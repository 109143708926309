<template>
  <div>
    <b-loading is-full-page :active="prefecturesLoading" can-cancel></b-loading>

    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container is-title-fullhd">
          <div class="level">
            <div class="level-left">
              <div class="is-justify-content-center">
                <h1 class="title">Prefeituras</h1>
              </div>
            </div>
            <div class="level-end has-text-centered">
              <button
                class="button is-primary is-inverted is-outlined"
                :class="{ ' is-loading': loading.general }"
                @click.prevent.stop="() => (novoComprador = true)"
              >
                Cadastrar Nova
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-fullhd">
        <Filtro />

        <div v-if="!prefectures.length" class="panel">
          <div class="panel-block div-paragraph">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Nenhum comprador.</p>
              </div>
            </section>
          </div>
        </div>

        <div v-if="prefectures.length" class="panel">
          <div class="panel-block">
            <b-table
              backend-pagination
              class="table is-fullwidth"
              default-sort-direction="ASC"
              :data="prefectures"
              :mobile-card="false"
            >
              <b-table-column
                sortable
                field="criada"
                label="Data de criação"
                width="200"
                v-slot="props"
                >{{ props.row.createdAt | formatarData }}</b-table-column
              >

              <b-table-column sortable field="nomeFantasia" label="Prefeitura" v-slot="props">{{
                props.row.fantasyName || 'Em Cadastro'
              }}</b-table-column>

              <b-table-column label="CNPJ" v-slot="props">{{ props.row.cnpj }}</b-table-column>

              <b-table-column sortable field="uf" label="UF" width="100" v-slot="props">{{
                props.row.state ? props.row.state : 'N/A'
              }}</b-table-column>

              <b-table-column centered width="60" v-slot="props">
                <b-tooltip label="Editar">
                  <button
                    class="button is-primary"
                    :disabled="desabilitar.editar"
                    ref="editar"
                    @click.prevent.stop="editarComprador(props.row.id)"
                  >
                    <b-icon icon="pencil" size="is-small" />
                  </button>
                </b-tooltip>
              </b-table-column>

              <template v-slot:footer>
                <AppPagination
                  :data-filters="prefecturesFilters"
                  :total="prefecturesTotal"
                  @change-page="page => filterPrefectures({ page })"
                  @change-per-page="perPage => changePerPagePrefectures(perPage)"
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>

    <b-modal
      has-modal-card
      v-if="novoComprador"
      :active.sync="novoComprador"
      :onCancel="() => (loading.general = false)"
      @cadastrar-comprador="criarPrefeitura"
    >
      <AppModalCnpj />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { funcoes } from '@/utils/enumerados';
import { formatarData } from '@/utils/format';
import AppPagination from '@/components/AppPagination';
import Filtro from './Filtro';
import AppModalCnpj from '../../../components/AppModalCnpj';

export default {
  name: 'ListaPrefeituras',
  components: {
    Filtro,
    AppModalCnpj,
    AppPagination,
  },
  data() {
    const loading = {
      general: false,
    };

    const desabilitar = {
      editar: false,
    };

    return {
      funcoes,
      loading,
      desabilitar,
      novoComprador: false,
    };
  },
  computed: {
    ...mapState('prefectures', [
      'prefectures',
      'prefecturesFilters',
      'prefecturesLoading',
      'prefecturesTotal',
    ]),
  },
  filters: {
    formatarData,
  },
  methods: {
    ...mapActions('prefectures', [
      'changePerPagePrefectures',
      'filterPrefectures',
      'createPrefecture',
    ]),
    async editarComprador(id) {
      this.desabilitar.editar = true;
      await this.$router.push({
        name: 'CadastroPrefeituras',
        params: { id },
      });
      this.desabilitar.editar = false;
    },
    async criarPrefeitura(cnpj) {
      this.loading.general = true;
      try {
        const id = await this.createPrefecture(cnpj);
        if (id) {
          this.$router.push({
            name: 'DadosCadastroPrefeitura',
            params: { id },
          });
        }
      } catch (error) {
        this.$alerta('Erro ao criar a prefeitura', 'is-danger');
      }
      this.loading.general = false;
    },
  },
};
</script>

<style lang="scss">
.table-item {
  background-color: #fafafa;
}

.is-centered {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.filtrar {
  margin-top: 0.2rem;
  height: 90%;
}

.field.is-grouped .field {
  flex-shrink: 1;
}

.div-paragraph {
  justify-content: center !important;
}

@media (max-width: 800px) {
  .is-justify-content-center {
    margin-bottom: 1rem;
    text-align: center !important;
  }
}

@media (min-width: 1920px) {
  .app-titulo {
    margin-left: 80px;
    margin-right: 80px;
    max-width: none;
    width: auto;
  }
}
</style>
