<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <AppTitulo titulo="Prefeitura" />

    <section class="section">
      <div class="container is-fullhd">
        <div class="columns">
          <div class="column is-narrow">
            <MenuSecundario titulo="Perfil" :itens="menu" />
          </div>
          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AppTitulo from '@/components/AppTitulo';
import MenuSecundario from '@/components/MenuSecundario';

export default {
  name: 'CadastroPrefeitura',
  components: {
    AppTitulo,
    MenuSecundario,
  },
  data() {
    const loading = {
      general: false,
    };

    return {
      loading,
    };
  },
  computed: {
    menu() {
      return [
        {
          titulo: 'Dados Cadastrais',
          rota: 'DadosCadastroPrefeitura',
        },
        {
          titulo: 'Usuários',
          rota: 'PrefeituraUsuarios',
        },
        {
          titulo: 'Configurações',
          rota: 'CompanyConfigurations',
        },
      ];
    },
  },
  methods: {
    ...mapActions('prefectures', ['fetchPrefecture']),
  },
  async created() {
    this.loading.general = true;
    try {
      await this.fetchPrefecture(this.$route.params.id);
    } catch (error) {
      this.$alerta('Erro ao buscar prefeitura', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>
