<template>
  <form>
    <div class="modal-card" style="width: auto">
      <section class="modal-card-body">
        <p class="paragraph-question">Qual o CNPJ do Comprador?</p>
        <b-field
          expanded
          :type="validaCnpj ? 'is-danger' : ''"
          :message="validaCnpj ? 'Insira um CNPJ válido.' : ''"
        >
          <b-input
            ref="cnpj"
            placeholder="CNPJ"
            maxlength="18"
            :has-counter="false"
            v-mascara:cnpj
            v-model="$v.dados.cnpj.$model"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click.stop.prevent="fechaModal()">
          Cancelar
        </button>
        <button class="button is-primary" @click.stop.prevent="enviarInformacoes()">
          Criar
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { isValidCnpj } from '@brazilian-utils/validators';
import campo from '@/mixins/formulario';

export default {
  name: 'AppModalCnpj',
  mixins: [campo],
  data() {
    const dados = {
      cnpj: '',
    };
    return {
      dados,
    };
  },
  computed: {
    validaCnpj() {
      return this.dados.cnpj.length === 18 && !isValidCnpj(this.dados.cnpj);
    },
  },
  validations: {
    dados: {
      cnpj: { required },
    },
  },
  methods: {
    validar() {
      if (this.$v.dados.cnpj.$invalid) {
        this.$v.dados.$touch();
        return false;
      }
      return true;
    },
    enviarInformacoes() {
      if (this.validar()) {
        this.$parent.$emit('cadastrar-comprador', this.dados.cnpj);
      }
      this.fechaModal();
    },
    fechaModal() {
      this.$parent.onCancel();
      this.$parent.close();
    },
  },
  mounted() {
    this.$refs.cnpj.focus();
  },
};
</script>

<style lang="scss" scoped>
.paragraph-question {
  margin-bottom: 1rem;
}

.modal-card {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-card-foot {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #dbdbdb;
  -webkit-box-pack: end !important;
  justify-content: flex-end !important;
}
</style>
