<template>
  <div class="panel">
    <div class="panel-heading">Filtros</div>
    <div class="panel-block" @keyup.enter="filterPrefectures">
      <b-field expanded grouped class="column-direction-touch is-flex-grow-1">
        <b-field class="break-attachment-field" expanded label="Período">
          <b-field expanded>
            <b-datepicker
              editable
              expanded
              placeholder="Início"
              icon="calendar-today"
              v-mascara:data
              :max-date="startMaximumDate"
              v-model="filters.period.start"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            >
              <button
                class="button is-primary br-4 mr-1"
                @click.prevent.stop="insertTodayFilter('start')"
              >
                <b-icon icon="calendar-today" />
                <span>Hoje</span>
              </button>

              <button class="button is-danger br-4" @click.prevent.stop="cleanDateFilter('start')">
                <b-icon icon="close" />
                <span>Limpar</span>
              </button>
            </b-datepicker>

            <b-datepicker
              editable
              expanded
              placeholder="Fim"
              icon="calendar-today"
              :disabled="disablePeriod"
              v-mascara:data
              :min-date="endMinimumDate"
              v-model="filters.period.end"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            >
              <button
                class="button is-primary br-4 mr-1"
                @click.prevent.stop="insertTodayFilter('end')"
              >
                <b-icon icon="calendar-today" />
                <span>Hoje</span>
              </button>

              <button class="button is-danger br-4" @click.prevent.stop="cleanDateFilter('end')">
                <b-icon icon="close" />
                <span>Limpar</span>
              </button>
            </b-datepicker>
          </b-field>
        </b-field>

        <b-field label="Prefeitura">
          <b-input
            custom-class="uppercase"
            placeholder="Nome da Prefeitura"
            v-model="filters.prefectureName"
            @blur="event => converterMaiusculo('prefectureName', event.target.value, 'filters')"
          />
        </b-field>

        <div class="column-direction-touch filters-buttons-container">
          <button class="button is-primary filtrar" @click.stop.prevent="validateFilters">
            Filtrar
          </button>

          <button class="button is-primary filtrar" @click.stop.prevent="cleanFilters">
            Limpar
          </button>
        </div>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import fieldMixin from '@/mixins/formulario';

export default {
  name: 'FiltroPrefeitura',
  mixins: [fieldMixin],
  data() {
    const filters = {
      period: {
        start: null,
        end: null,
      },
      prefectureName: '',
      page: 1,
      buyer: true,
    };

    return {
      filters,
    };
  },
  computed: {
    disablePeriod() {
      return this.filters.period.start === null;
    },
    endMinimumDate() {
      const start = new Date(this.filters.period.start);
      if (start) {
        start.setDate(start.getDate() + 1);
        return start;
      }
      return null;
    },
    startMaximumDate() {
      if (this.filters.period.end) {
        const data = new Date(this.filters.period.end);
        data.setDate(data.getDate() - 1);
        return data;
      }
      return null;
    },
  },
  methods: {
    ...mapActions('prefectures', ['filterPrefectures']),
    cleanPeriod() {
      this.filters.period.start = null;
      this.filters.period.end = null;
    },
    validateFilters() {
      if (
        this.filters.period.start &&
        this.filters.period.end &&
        this.filters.period.start > this.startMaximumDate
      ) {
        this.cleanPeriod();
        return this.$alerta('O período deve ter no mínimo um dia', 'is-danger');
      }
      if (
        this.filters.period.start &&
        this.filters.period.end &&
        this.filters.period.end < this.endMinimumDate
      ) {
        this.cleanPeriod();
        return this.$alerta('O período deve ter no mínimo um dia', 'is-danger');
      }
      return this.filterPrefectures(this.filters);
    },
    cleanFilters() {
      this.filters.period.start = null;
      this.filters.period.end = null;
      this.filters.prefectureName = '';
      this.filterPrefectures();
    },
  },
  created() {
    this.filterPrefectures(this.filters);
  },
};
</script>

<style lang="scss" scoped>
.filtro {
  height: 100%;
}

.field.is-grouped {
  width: 100%;
  justify-content: space-between;
}

.button-box {
  display: flex;
  justify-content: center !important;

  button {
    margin: 5px;
    height: 75px;
  }
}

@media (max-width: 800px) {
  .field.is-grouped {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }

  .button-box {
    width: 100%;
    margin-top: 0.5rem;

    button {
      height: 100%;
    }
  }
}
</style>
