<template>
  <div>
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container">
          <div class="level">
            <div class="level-left">
              <div>
                <h1 class="title">Relatório Vencedores</h1>
              </div>
            </div>
            <div class="level-end hash-text-centered">
              <button class="acoes button is-primary is-inverted is-outlined">
                Imprimir
              </button>
              <button class="acoes button is-primary is-inverted is-outlined">
                Exportar
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-fluid">
        <Filtro />

        <div class="panel">
          <div class="panel-block">
            <b-field grouped group-multiline>
              <div class="control">
                <b-switch>Exibir somente selecionados</b-switch>
              </div>
            </b-field>
          </div>
          <div class="panel-block">
            <b-table
              paginated
              class="table is-fullwidth"
              default-sort-direction="ASC"
              backend-pagination
              :current-page.sync="paginaAtual"
              :per-page="porPagina"
              :data="dadosEmpresas"
              :checked-rows.sync="selecionados"
              :mobile-card="false"
            >
              <template>
                <b-table-column
                  sortable
                  width="200"
                  label="Periodo"
                  field="Periodo"
                ></b-table-column>

                <b-table-column
                  sortable
                  width="200"
                  label="Vencedor"
                  field="Vencedor"
                ></b-table-column>

                <b-table-column sortable width="200" label="Item" field="item"></b-table-column>

                <b-table-column sortable width="200" label="Valor" field="valor"></b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Filtro from './Filtro';

export default {
  name: 'RelatorioVencedores',
  components: {
    Filtro,
  },
  data() {
    return {
      paginaAtual: 1,
      porPagina: 20,
      selecionados: [],
      somenteSelecionados: false,
      vendedor: true,
    };
  },
  computed: {
    ...mapGetters({
      relatorios: 'obterRelatoriosVencedores',
    }),
    dadosEmpresas() {
      return this.somenteSelecionados ? this.selecionados : this.relatorios;
    },
  },
  methods: {
    ...mapActions(['buscarRelatorioVencedores']),
    async filtrarRelatorios(filtros) {
      await this.buscarRelatorioVencedores(filtros);
    },
  },
};
</script>

<style lang="scss">
.table-item {
  background-color: #fafafa;
}

.is-centered {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.filtrar {
  margin-top: 0.2rem;
  height: 90%;
}

.field.is-grouped .field {
  flex-shrink: 1;
}
</style>
