<template>
  <div class="panel">
    <div class="panel-heading">Filtros</div>
    <form class="panel-block">
      <b-field grouped>
        <b-field label="Data">
          <b-field>
            <b-datepicker editable placeholder="Inicio" icon="calendar-today" v-mascara:data>
              <button class="button is-primary">
                <b-icon icon="calendar-today" />
                <span>Hoje</span>
              </button>

              <button class="button is-danger">
                <b-icon icon="close" />
                <span>Limpar</span>
              </button>
            </b-datepicker>

            <b-datepicker
              editable
              placeholder="Fim"
              icon="calendar-today"
              v-mascara:data
              :min-date="dataMinima"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            >
              <button class="button is-primary br-4 mr-1">
                <b-icon icon="calendar-today" />
                <span>Hoje</span>
              </button>

              <button class="button is-danger br-4">
                <b-icon icon="close" />
                <span>Limpar</span>
              </button>
            </b-datepicker>
          </b-field>
        </b-field>

        <b-field label="Empresa">
          <b-input custom-class="uppercase" placeholder="Nome da empresa" />
        </b-field>

        <b-field label="Encerrado">
          <b-select placeholder="Encerrado">
            <option v-for="(tipo, index) in tiposEncerramento" :key="index" :value="tipo">{{
              tipo
            }}</option>
          </b-select>
        </b-field>

        <b-field>
          <b-field class="filtro">
            <button class="button is-primary filtrar">Filtrar</button>
          </b-field>

          <b-field class="filtro">
            <button class="button is-primary filtrar">Limpar</button>
          </b-field>
        </b-field>
      </b-field>
    </form>
  </div>
</template>

<script>
import campo from '@/mixins/formulario';

export default {
  name: 'FiltroRelatorios',
  mixins: [campo],
  data() {
    const tiposEncerramento = ['VENCEDOR', 'RECURSO'];

    const filtros = {
      tipo: 'todos',
      periodo: {
        inicio: null,
        fim: null,
      },
      pagina: 1,
    };

    return { tiposEncerramento, filtros };
  },
  computed: {
    dataMinima() {
      const inicio = new Date(this.filtros.periodo.inicio);

      if (inicio) {
        inicio.setDate(inicio.getDate() + 1);
        return inicio;
      }

      return null;
    },
  },
  methods: {
    limparPeriodo() {
      this.filtros.periodo.inicio = null;
      this.filtros.periodo.fim = null;
    },
    filtrarEmpresas() {
      this.$emit('filtrar', this.filtros);
    },
    limparFiltros() {
      this.filtros.tipo = 'todos';
      this.filtros.periodo.inicio = null;
      this.filtros.periodo.fim = null;
      this.filtrarEmpresas();
    },
  },
};
</script>

<style scoped>
.filtro {
  height: 100%;
}
</style>
